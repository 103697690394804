* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

$background-color: #fff;
$background-color2: #dadada;
$cardbg-color: #ff9f31;
$fontsizesmall: 10px;
$fontsizemedium: 12px;
$fontsizelarge: 30px;

footer {
  .top_footer {
    background-color: $background-color2;
    padding: 1rem 0;

    .about_us_footer {
      padding: 1rem;
      font-family: "Dosis", sans-serif;
      text-align: start;
      h1 {
        font-size: $fontsizelarge;
      }
    }
    .contact_footer {
      padding: 1rem;
      font-family: "Dosis", sans-serif;
      text-align: start;
      h1 {
        font-size: $fontsizelarge;
      }
      ul {
        list-style: none;
        padding-left: 0;
        a{
          text-decoration: none;
          color: #000000;
        }
      }
    }
    .social_network {
      display: flex;
      justify-content: center;
      a {
        width: 30px;
        color: #26007e;
        font-size: 20px;
      }
    }
  }
  .ask_me {
    padding: 1rem;
    h1 {
      font-size: $fontsizelarge;
      margin-bottom: 1rem;
      font-family: "Dosis", sans-serif;
    }
    input {
      width: 90%;
      margin-bottom: 10px;
      padding: 3px 5px;
      border-radius: 5px;
      outline: none !important;
      border: 1px solid #6e6e6e;
    }
    .questionarea {
      width: 90%;
      margin-bottom: 10px;
      padding: 3px 5px;
      border-radius: 5px;
      outline: none !important;
    }

    .Send_question {
      width: 40%;
      align-self: center;
      background-color: #fff;
      background-image: none;
      background-position: 0 90%;
      background-repeat: repeat no-repeat;
      background-size: 4px 3px;
      border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
      border-style: solid;
      border-width: 2px;
      box-shadow: rgba(131, 131, 131, 0.2) 15px 28px 25px -18px;
      box-sizing: border-box;
      color: #41403e;
      cursor: pointer;
      display: inline-block;
      font-family: Neucha, sans-serif;
      font-size: 1rem;
      line-height: 23px;
      outline: none;
      padding: 0.75rem;
      text-decoration: none;
      transition: all 235ms ease-in-out;
      border-bottom-left-radius: 15px 255px;
      border-bottom-right-radius: 225px 15px;
      border-top-left-radius: 255px 15px;
      border-top-right-radius: 15px 225px;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      &:hover {
        box-shadow: rgba(109, 109, 109, 0.3) 2px 8px 8px -5px;
        transform: translate3d(0, 2px, 0);
      }
      &:focus {
        box-shadow: rgba(112, 112, 112, 0.3) 2px 8px 4px -6px;
      }
    }
  }
  .copyrigt_footer {
    margin: 5px;
    font-size: 12px;
    text-align: center;
  }
}
