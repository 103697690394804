.poster_section {
  border-bottom: 1px solid #a1a1a1;
  img {
    height: 90vh;
    object-fit: cover;
    object-position: bottom;
    @media (max-width: 1200px) {
      height: 70vh;
    }
    @media (max-width: 992px) {
      height: 60vh;
    }
    @media (max-width: 768px) {
      height: 50vh;
    }
    @media (max-width: 576px) {
      height: 40vh;
    }
  }

  h5 {
    font-size: 60px;
    font-weight: 700;
    text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
  }
  P {
    font-size: 24px;
    text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
    font-style: italic;
    font-weight: 500;
  }
}

.product_section {
  // border-bottom:1px solid #a1a1a1;
  padding: 2rem 0;
  // background-image: url(../../Assets/images/Bg_product_section.jpg);
  // background-repeat: no-repeat;
  // background-position: bottom;
  // background-size: cover;
  .title_section {
    font-size: 40px;
    margin: 1rem;
    text-align: center;
  }
  hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
  }
  .card_product {
    background-color: #eeeeee;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .card_product_img {
      width: 90%;
      margin: 1rem auto;
      overflow: hidden;

      img {
        width: 100%;
        height: 200px;
        transition: 1s;
        cursor: pointer;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
    .card_product_desc {
      padding: 2rem 0;
      h1 {
        font-size: 24px;
        margin-bottom: 6px;
        text-align: center;
      }
      p {
        margin-bottom: 1.5rem;
      }
      a {
        background-color: #fff;
        color: black;
        padding: 12px 30px;
        text-decoration: none;
        transition: 0.5s;
        &:hover {
          background-color: #ff933a;
        }
      }
    }
  }
}
.news_section {
  // border-bottom:1px solid #a1a1a1;
  padding: 2rem 0;
}

.partners_section {
  padding: 2rem 0;
  // border-bottom:1px solid #a1a1a1;
}

section {
  .title_section {
    font-size: 40px;
    margin: 1rem;
    text-align: center;
  }
  hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
  }
}
