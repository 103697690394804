* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.title_section {
  font-size: 40px;
  margin: 1rem;
  text-align: center;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.partners_page {
  padding: 1rem 0;
}/*# sourceMappingURL=Partners.css.map */