* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.contact_page {
  .contact_details {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;

    @media (max-width: 992px) {
      height: 200px !important;
    }
    ul {
      list-style: none;
      text-align: start;
      padding: 40px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      li {
        font-family: "Dosis", sans-serif;
        font-size: 20px;
        font-weight: normal;
        color: #16161d;
        a {
          text-decoration: none;
          color: #16161d;
        }
      }
    }
  }
}
