.card_news {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  transition: transform 0.2s ease-in-out;
  width: 90%;
  height: 260px;
  margin: 1rem;
}
.card_news .card-image {
  width: 50%;
  background-color: rgb(29, 29, 29) !important;
}
.card_news .card-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.card_news .card_right {
  width: 50%;
}
.card_news .card_right .card-content {
  padding: 10px;
}
.card_news .card_right .card-content h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
}
.card_news .card_right .card-content p {
  padding: 5px;
  margin-top: 8px;
  color: #666;
  text-align: justify;
}
.card_news .card_right .card-content p a {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
}
.card_news .card_right .thumbnail-container {
  display: flex;
  margin: 12px 10px 10px 10px;
}
.card_news .card_right .thumbnail-container .thumbnail {
  max-width: 60px;
  height: auto;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
  width: 30%;
}
.card_news .card_right .thumbnail-container .thumbnail.selected {
  opacity: 1;
}/*# sourceMappingURL=Newsbox.css.map */