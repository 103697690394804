.company-logo-box {
  height: 12rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  a{
    text-decoration: none;
  }
  .logo-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .company-name {
    margin-top: 10px;
    font-size: 18px;
    color: #333;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
}
