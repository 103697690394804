.partner_single_page {
  padding: 1rem 0;
  h1 {
    font-size: 40px;
    margin: 1rem;
    text-align: center;
  }
  hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
  }
  .logo_partner {
    width: 300px;
    margin: auto;
    text-align: center;
    img {
      height: 150px;
    }
  }
  .partner_contetnt {
    padding: 2rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    border-radius: 10px;
    margin-bottom: 1rem;
    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      span {
        font-size: 20px;
        font-weight: 300;
      }
    }
    p {
        text-align: justify;
    }
  }
  .partner_products{
    img{width: 100%;}
  }
}
