.poster_section {
  border-bottom: 1px solid #a1a1a1;
}
.poster_section img {
  height: 90vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
}
@media (max-width: 1200px) {
  .poster_section img {
    height: 70vh;
  }
}
@media (max-width: 992px) {
  .poster_section img {
    height: 60vh;
  }
}
@media (max-width: 768px) {
  .poster_section img {
    height: 50vh;
  }
}
@media (max-width: 576px) {
  .poster_section img {
    height: 40vh;
  }
}
.poster_section h5 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
}
.poster_section P {
  font-size: 24px;
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  font-style: italic;
  font-weight: 500;
}

.product_section {
  padding: 2rem 0;
}
.product_section .title_section {
  font-size: 40px;
  margin: 1rem;
  text-align: center;
}
.product_section hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}
.product_section .card_product {
  background-color: #eeeeee;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.product_section .card_product .card_product_img {
  width: 90%;
  margin: 1rem auto;
  overflow: hidden;
}
.product_section .card_product .card_product_img img {
  width: 100%;
  height: 200px;
  transition: 1s;
  cursor: pointer;
}
.product_section .card_product .card_product_img img:hover {
  transform: scale(1.2);
}
.product_section .card_product .card_product_desc {
  padding: 2rem 0;
}
.product_section .card_product .card_product_desc h1 {
  font-size: 24px;
  margin-bottom: 6px;
  text-align: center;
}
.product_section .card_product .card_product_desc p {
  margin-bottom: 1.5rem;
}
.product_section .card_product .card_product_desc a {
  background-color: #fff;
  color: black;
  padding: 12px 30px;
  text-decoration: none;
  transition: 0.5s;
}
.product_section .card_product .card_product_desc a:hover {
  background-color: #ff933a;
}

.news_section {
  padding: 2rem 0;
}

.partners_section {
  padding: 2rem 0;
}

section .title_section {
  font-size: 40px;
  margin: 1rem;
  text-align: center;
}
section hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}/*# sourceMappingURL=Home.css.map */