.news_page .title_section {
  font-size: 40px;
  margin: 1rem;
  text-align: center;
}
.news_page hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}
.news_page .loadmore {
  --b: 3px;
  --s: 0.45em;
  --color: #373b44;
  width: 12rem;
  margin: auto;
  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(from 90deg at var(--b) var(--b), rgba(0, 0, 0, 0) 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid rgba(0, 0, 0, 0);
  outline-offset: 0.6em;
  font-size: 16px;
  border: 0;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.news_page .loadmore:hover, .news_page .loadmore:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
}
.news_page .loadmore:active {
  background: var(--color);
  color: #fff;
}/*# sourceMappingURL=News.css.map */