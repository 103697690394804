.company-logo-box {
  height: 12rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.company-logo-box a {
  text-decoration: none;
}
.company-logo-box .logo-image {
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}
.company-logo-box .company-name {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}
@media (max-width: 992px) {
  .company-logo-box .company-name {
    font-size: 14px;
  }
}/*# sourceMappingURL=Partnerbox.css.map */