.productbox {
  width: auto;
  height: 20rem;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: rgb(240, 240, 240);
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
}
.productbox .img_box {
  position: absolute;
  bottom: 22%;
  left: 50%;
  transform: translate(-50%);
  width: 60%;
  height: 60%;
  margin: auto;
  -o-object-position: bottom;
     object-position: bottom;
  transition: 0.7s;
}
.productbox .img_box img {
  filter: drop-shadow(6px 0px 16px #636363);
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: bottom;
     object-position: bottom;
}
.productbox h2 {
  position: absolute;
  inset: auto auto 30px 50%;
  transform: translate(-50%);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: black;
  text-align: center;
  width: 80%;
  margin: auto;
  max-width: 80%;
  transition: 0.7s;
}
.productbox .wrap-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.productbox a {
  position: absolute;
  inset: auto auto -100% 50%;
  transform: translate(-50%);
  max-width: 80%;
  text-decoration: none;
  transition: 0.7s;
  --b: 3px;
  --s: 0.45em;
  --color: #373b44;
  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(from 90deg at var(--b) var(--b), rgba(0, 0, 0, 0) 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2 * var(--_p)) calc(100% - var(--b) - 2 * var(--_p));
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid rgba(0, 0, 0, 0);
  outline-offset: 0.6em;
  font-size: 16px;
  border: 0;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.productbox a:hover, .productbox a:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
}
.productbox a:active {
  background: var(--color);
  color: #fff;
}
.productbox:hover h2 {
  inset: auto auto -100% 50%;
  color: white;
  filter: drop-shadow(6px 0px 16px #636363);
}
.productbox:hover a {
  inset: auto auto 30px 50%;
  text-align: center;
  color: #0e0d55;
  font-weight: 700;
}
.productbox:hover .img_box {
  filter: blur(6px);
}

@media (max-width: 1200px) {
  .productbox {
    width: 13rem;
    height: 18rem;
  }
}
@media (max-width: 440px) {
  .productbox {
    width: 10rem;
    height: 15rem;
  }
  .productbox h2 {
    position: absolute;
    inset: auto auto 30px 50%;
    font-size: 12px;
  }
  .productbox:hover h2 {
    inset: auto auto -100% 50%;
    transition: inset 0.3s ease-out;
  }
  .productbox .img_box {
    bottom: 30%;
  }
}/*# sourceMappingURL=Productbox.css.map */