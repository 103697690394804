* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

footer .top_footer {
  background-color: #dadada;
  padding: 1rem 0;
}
footer .top_footer .about_us_footer {
  padding: 1rem;
  font-family: "Dosis", sans-serif;
  text-align: start;
}
footer .top_footer .about_us_footer h1 {
  font-size: 30px;
}
footer .top_footer .contact_footer {
  padding: 1rem;
  font-family: "Dosis", sans-serif;
  text-align: start;
}
footer .top_footer .contact_footer h1 {
  font-size: 30px;
}
footer .top_footer .contact_footer ul {
  list-style: none;
  padding-left: 0;
}
footer .top_footer .contact_footer ul a {
  text-decoration: none;
  color: #000000;
}
footer .top_footer .social_network {
  display: flex;
  justify-content: center;
}
footer .top_footer .social_network a {
  width: 30px;
  color: #26007e;
  font-size: 20px;
}
footer .ask_me {
  padding: 1rem;
}
footer .ask_me h1 {
  font-size: 30px;
  margin-bottom: 1rem;
  font-family: "Dosis", sans-serif;
}
footer .ask_me input {
  width: 90%;
  margin-bottom: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  outline: none !important;
  border: 1px solid #6e6e6e;
}
footer .ask_me .questionarea {
  width: 90%;
  margin-bottom: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  outline: none !important;
}
footer .ask_me .Send_question {
  width: 40%;
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(131, 131, 131, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
footer .ask_me .Send_question:hover {
  box-shadow: rgba(109, 109, 109, 0.3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}
footer .ask_me .Send_question:focus {
  box-shadow: rgba(112, 112, 112, 0.3) 2px 8px 4px -6px;
}
footer .copyrigt_footer {
  margin: 5px;
  font-size: 12px;
  text-align: center;
}/*# sourceMappingURL=Footer.css.map */