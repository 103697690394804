.news_page_special h1 {
  font-size: 40px;
  margin: 1rem;
  text-align: center;
}
.news_page_special hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}
.news_page_special .left_side .top_img {
  width: 100%;
  max-height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
.news_page_special .left_side .img_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
.news_page_special .left_side .img_list img {
  width: calc(25% - 10px);
  margin-bottom: 10px;
  cursor: pointer;
}
.news_page_special .right_side {
  width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.news_page_special .right_side p {
  padding: 1rem;
  font-size: 16px;
  word-wrap: break-word;
  text-align: justify;
}
.news_page_special .right_side .date_news {
  padding: 1rem;
  font-weight: 700;
}/*# sourceMappingURL=Newsspecialpage.css.map */