.image-wrapper {
  padding: 2rem 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  cursor: pointer;
  
  .image-container {
    flex-shrink: 0;
    margin-right: 20px;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    img {
      height: 100%;
      width: auto;
    }
  }
}
