*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.about_page{
    h1{
        margin: 2rem 0;
        font-size: 40px;
        text-align: center;
    }
    img{
        width: 100%;
    }
    p{
      text-align: justify;
    }
}