.loading-area {
  display: grid;
  place-items: center;
  height: 30vh;
}
.loading-area .loader div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transform: scale(0);
  animation: animate 1.5s ease-in-out infinite;
  display: inline-block;
  margin: 0.5rem;
}
.loading-area .loader div:nth-child(0) {
  animation-delay: 0s;
}
.loading-area .loader div:nth-child(1) {
  animation-delay: 0.2s;
}
.loading-area .loader div:nth-child(2) {
  animation-delay: 0.4s;
}
.loading-area .loader div:nth-child(3) {
  animation-delay: 0.6s;
}
.loading-area .loader div:nth-child(4) {
  animation-delay: 0.8s;
}
.loading-area .loader div:nth-child(5) {
  animation-delay: 1s;
}
.loading-area .loader div:nth-child(6) {
  animation-delay: 1.2s;
}
.loading-area .loader div:nth-child(7) {
  animation-delay: 1.4s;
}
@keyframes animate {
  0%, 100% {
    transform: scale(0.2);
    background-color: #ffb13b;
  }
  40% {
    transform: scale(1);
    background-color: #436fff;
  }
  50% {
    transform: scale(1);
    background-color: #00f249;
  }
}/*# sourceMappingURL=Loading.css.map */