* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.about_page h1 {
  margin: 2rem 0;
  font-size: 40px;
  text-align: center;
}
.about_page img {
  width: 100%;
}
.about_page p {
  text-align: justify;
}/*# sourceMappingURL=About.css.map */