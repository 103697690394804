.product_single_page {
  margin: 3rem 0;
  .product_image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    img {
      padding-top: 3rem;
      filter: drop-shadow(-3px 23px 33px #757575);
      width: auto;
      max-width: 300px;
      max-height: 300px;
    }
    @media (max-width: 768px) {
      img {
        width: auto;
        max-height: 200px;
      }
    }
  }
  .product_content {
    margin-top: 3rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 1rem;
    h1 {
      margin: 1rem;
      font-weight: 500;
      font-size: 20px;
      span {
        font-weight: 700 !important;
        font-size: 20px;
      }
    }
    p {
      margin: 1rem;
      font-weight: 500;
      font-size: 18px;
    }
    @media (max-width: 576px) {
      h1 {
        font-size: 14px;
        span {
          font-size: 14px;
        }
      }
      p {
        margin: 1rem;
        font-size: 12px;
      }
    }
  }
}
