* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.active {
  color: rgb(207, 89, 10) !important;
}

.activeiconsidebar {
  filter: drop-shadow(0 0 0.75rem rgb(255, 156, 99));
}

.show {
  display: none !important;
}

header {
  padding: 10px 0;
  background-color: #ffffff;
}
header .header_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .header_area .logo {
  width: 10%;
  background-color: #fff;
}
header .header_area .logo img {
  width: 100%;
  filter: invert(10%) sepia(50%) saturate(6000%) hue-rotate(245deg) brightness(100%) contrast(150%);
}
@media (max-width: 992px) {
  header .header_area .logo {
    width: 25%;
  }
}
header .header_area nav {
  width: 55%;
}
header .header_area nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
header .header_area nav ul li {
  font-weight: 700;
  font-family: "Dosis", sans-serif;
  margin-right: 15px;
}
header .header_area nav ul li a {
  position: relative;
  display: block;
  text-transform: uppercase;
  margin: 10px 0;
  padding: 2px 2px;
  text-decoration: none;
  color: #262626;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 600;
  transition: 0.5s;
  z-index: 1;
}
header .header_area nav ul li a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 2px solid #262626;
  border-bottom: 2px solid #262626;
  transform: scaleY(2);
  opacity: 0;
  transition: 0.3s;
}
header .header_area nav ul li a:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #262626;
  transform: scale(0);
  opacity: 0;
  transition: 0.3s;
  z-index: -1;
}
header .header_area nav ul li a:hover {
  color: #fff;
}
header .header_area nav ul li a:hover:before {
  transform: scaleY(1);
  opacity: 1;
}
header .header_area nav ul li a:hover:after {
  transform: scaleY(1);
  opacity: 1;
}
@media (max-width: 1200px) {
  header .header_area nav ul li a {
    font-size: 11px;
  }
}
@media (max-width: 992px) {
  header .header_area nav {
    display: none;
  }
}
header .header_area .search {
  width: 25%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 5px;
}
header .header_area .search .search_input {
  font-family: "Dosis", sans-serif;
  padding: 5px;
  width: 90%;
  border: none;
  outline: none !important;
  border-radius: 3px;
  margin-right: 5px;
}
header .header_area .search .search_Btn {
  font-size: 20px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding-bottom: 3px;
}
header .header_area .search .search_results {
  background-color: #ffffff;
  border: 1px solid rgb(160, 160, 160);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  z-index: 111;
  overflow-y: scroll;
  border-radius: 10px;
}
header .header_area .search .search_results ul {
  list-style: none;
}
header .header_area .search .search_results ul li a {
  padding-top: 1rem;
  text-decoration: none;
  color: #007bff;
}
header .header_area .search .search_results ul li a .result_img {
  width: 30%;
}
header .header_area .search .search_results ul li a .result_img img {
  height: 30px;
}
header .header_area .search .search_results ul li a span {
  width: 50%;
  text-align: end;
  font-size: 12px;
}
@media (max-width: 992px) {
  header .header_area .search {
    width: 65%;
  }
}
@media (max-width: 576px) {
  header .header_area .search {
    width: 60%;
  }
}
header .header_area .languages span {
  font-weight: 700;
  font-family: "Dosis", sans-serif;
  padding: 2px;
  cursor: pointer;
}
@media (max-width: 992px) {
  header .header_area .languages {
    display: none;
  }
}
header .header_area .sidebar_menu_icon {
  display: none;
}
@media (max-width: 992px) {
  header .header_area .sidebar_menu_icon {
    font-size: 30px;
    padding-bottom: 2px;
    width: 10%;
    display: inline-block;
  }
}
header .sidebar {
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: fixed;
  top: 0;
  right: -300px;
  transition: right 0.3s ease;
  z-index: 1;
}
@media (min-width: 992px) {
  header .sidebar {
    display: none;
  }
}
header .sidebar.open {
  right: 0;
}
header .sidebar .sidebar_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
header .sidebar .sidebar_top .language_sidebar {
  width: 90%;
}
header .sidebar .sidebar_top .language_sidebar span {
  margin-left: 10px;
  color: #333;
  font-weight: 700;
  font-family: "Dosis", sans-serif;
}
header .sidebar .sidebar_top .language_sidebar select {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
header .sidebar .sidebar_top .close_sidebar {
  font-size: 24px;
  width: 10%;
}
header .sidebar hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}
header .sidebar .menu {
  list-style: none;
  padding: 0;
}
header .sidebar .menu li {
  margin-bottom: 10px;
}
header .sidebar .menu li a {
  font-family: "Dosis", sans-serif;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  font-size: 24px;
}
header .sidebar .menu li a:hover {
  color: #007bff;
}/*# sourceMappingURL=Header.css.map */