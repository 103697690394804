* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.products_page {
  .title {
    font-size: 40px;
    margin: 1rem;
    text-align: center;
  }
  hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
  }
  .category_web {
    h1 {
      font-size: 30px;
      text-align: center;
      
    }
    ul {
      list-style: none;

      li {
        text-align: center;
        margin-right: 30px;
        span {
          font-size: 20px;
          color: black;
          margin-bottom: 5px;
          cursor: pointer;
          color: #18272f;
          position: relative;
          text-align: center;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            border-radius: 4px;
            background-color: #18272f;
            bottom: 0;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform 0.3s ease-in-out;
          }

          &:hover::before {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .products_area {
    padding: 2rem 1rem;
    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      span{
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        color: #333;
        font-size: 16px;
        padding: 8px 12px;
        margin: 0 5px;
      }
      button {
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        color: #333;
        cursor: pointer;
        font-size: 16px;
        padding: 8px 12px;
        margin: 0 5px;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: #333;
          color: #fff;
        }

        &:focus {
          outline: none;
        }
      }

      .active {
        background-color: #333;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
